@import "../baseColor";

.menu-container {
    min-height: calc(100vh - 48px);

    .container-header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .container-title {
            flex: 1;

            .number-food {
                background-color: $colorBlack;
            }
        }

        p {
            color: rgba(0, 0, 0, 0.6);
        }

        p:hover {
            color: rgba(0, 0, 0, 1);
            cursor: pointer;
        }
    }

    .category-menu {

        .Categorie-menu-item-header {
            background-color: $colorBlack;
            color: $colorWhite;
            text-transform: uppercase;
        }

        .col-categories {
            background-color: rgba(0, 0, 0, 0.1);

            .category-menu-item {
                font-size: 0.8rem;
                font-weight: bold;
            }

            .category-menu-item div {
                display: flex;
                align-items: center;

                p {
                    flex: 1;
                    font-weight: normal;
                }

                .icon-active-category {
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            .category-menu-item.active-menu {
                background-color: rgba(0, 0, 0, 0.1);

                p {
                    font-weight: bold;
                }

                .icon-active-category {
                    color: rgba(0, 0, 0, 1);
                }
            }

            .category-menu-item.active-menu:hover {
                cursor: default;
                background-color: rgba(0, 0, 0, 0.1);
            }

            .category-menu-item:hover {
                background-color: $colorBackground;
                cursor: pointer;
            }

            .fixed-col-category {
                height: calc(100vh - 262px);
                // background-color: rgba(0, 0, 0, 0.1);
            }
        }

    }

    .search-bar-menu {
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        input {
            border: none;
            border-radius: 0px;
        }

        .btn {
            border: none;
            border-radius: 0px;
        }
    }

    .empty-result {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .list-dishes {
        height: calc(100vh - 156px);
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
        .card-menu-item {
            border: none;

            .card-menu-img {
                height: 120px;

                .menu-item-name {
                    width: 100%;
                    text-align: left;
                    color: $colorBackground;
                    background-color: rgba(0, 0, 0, 0.5);
                    font-size: 0.7rem;
                }
            }

            .card-menu-img:hover {
                opacity: 0.8;
                cursor: pointer;
            }

            .footer-menu-item {
                border: none;
                display: flex;
                align-items: center;
                background-color: $colorBackground;

                .col-price {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .col-price-text {
                        font-size: 0.7rem;
                    }

                    .col-price-value {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

    .btn-dish-short:hover {
        opacity: 0.5;
        cursor: pointer;
    }

}

.list-categories-added-item {
    display: flex;
    align-items: center;
    background-color: $colorBackground;
    border-radius: 15px;
    border: solid 1px rgba(0, 0, 0, 0.1);
}

.toggle-btn-add-category {
    border-radius: 15px;
}

.newDish-form {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: calc(100vh - 108px);
    .header-newDish {
        display: flex;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        align-items: center;

        h3 {
            flex: 1;
        }
    }
.max-number-limit{
    display: flex;
    align-items: center;
}
    .img-dish-placeholde {
        opacity: 1;
    }

    .img-dish-placeholder:hover {
        cursor: pointer;
        border: dashed 2px rgba(0, 0, 0, 0.5);
        opacity: 0.8;
       
    }

}

.img-dish {
    opacity: 0.8;
}

.img-dish:hover {
    opacity: 1;
    cursor: pointer;
}

.loading-dishes {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}