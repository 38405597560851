@import "../baseColor.scss";

.container-login{
    background-color: $colorBackground;
    position: relative;
    .row{
        min-height: 100vh;
        .container-login-form{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: $colorWhite;
            form{
                width: 100%;
                input{
                    border: 1px solid $colorBackground;
                    background-color: $colorBackground;
                }
                .btn-connexion{
                    width: 100%;
                    background-color: $colorOrange;
                    border-color: $colorOrange;
                    color: $colorBlack;
                }
                .password-forgot{
                    font-size: 0.9rem;
                    color: $colorBlack;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            
            
        }
        .login-img-bck{
            // height: 100vh;
        }
    
    }  
}

@media (max-width: 767.98px) { 
    .login-img-bck{
        display: none;
    }


 }

 @media (min-width: 768px) {
    .container-login{
        .row{
            .container-login-form{
                form{
                    width: 100%;
                }
            }
        }
    }
 }

 .container-email-change{
     height: 100vh;
     background-color: $colorBackground;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     .form-email-verify{
         width: 30%;
         .btn{
             width: 100%;
         }
     }
     @media (max-width: 767.98px) { 
        .form-email-verify{
            width: 95%;
        }
    
    
     }
 }

 .success-password{
     height: 100vh;
     background-color: $colorBackground;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
 }
 .warning-bloqued-restaurant{
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     height: 100vh;
 }

