@import "../baseColor";

.container-business {
    min-height: calc(100vh - 48px);
    .business-dashboard {
        height: calc(100vh - 100px);
        .row-sals {
            background-color: $colorWhite;
            box-shadow: 1px 2px 3px rgba($color: #000000, $alpha: 0.1);

            .days-data-input {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .days-data-item:hover {
                    cursor: pointer;
                }

                .days-data-item.active {
                    color: $colorOrange;
                    text-decoration: underline;
                }

                .year-filter:hover {
                    cursor: pointer;
                }
            }

            .number-orders {
                background-color: rgba($color: #00c853, $alpha: 0.8);
            }

            .total-money-div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100px;
                box-shadow: 1px 2px 3px rgba($color: #000000, $alpha: 0.1);
                border-radius: 10px;
                flex-direction: column;
                background-color: $colorBackground;

                .total-value {
                    font-size: 1.8rem;

                    span {
                        font-size: 1rem;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .spinner-chart-business{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:center;
        height: 300px;
    }
}

.year-filter:hover{
    cursor: pointer;
    background-color: $colorBackground;
}
