@import "../baseColor";

.container-settings {
    min-height: calc(100vh - 48px);

    .settings-menu{
        background-color: rgba(0, 0, 0, 0.1);
        height: calc(100vh - 124px);
        overflow-y: auto;
        .setting-menu-item{
            border-bottom: none;
        }
        .setting-menu-item:hover {
            cursor: pointer;
            background-color: $colorBackground;
        }

        .setting-menu-item.active-menu {
            font-weight: bold;
            background-color: rgba(0, 0, 0, 0.1);

            .icon-menu {
                color: $colorOrange;
            }
        }

        .setting-menu-item.active-menu:hover {
            cursor: default;
        }

    }
@media (max-width:"768.99px"){
    .settings-menu{
        height: auto;
    }
}
    .restoInfo{
        background-color: rgba(0, 0, 0, 0.1);
        height: calc(100vh - 124px);
        overflow-y: auto;
        .loading-resto-incator{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
        }
        .div-edit{
            display: flex;
            align-items: center;
            background-color: $colorWhite;
            p{
                flex: 1;
            }
        }
        .information-payement-div{
            background-color: rgba($color: #000000, $alpha:0.1);
            border-radius: 10px;
        }
    }
    .security-info{
        background-color: rgba($color: #000000, $alpha: 0.1);
        height: 150px;
        .security-header{
            background-color: transparent;
        }
    }
}