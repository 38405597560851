@import "../baseColor";
.sidebar{
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width:250px;
    background-color: $colorWhite;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    z-index: 300;
    transition: transform 0.6s ease-out;
    transform: translateX(-100%);
    .resto-banner{
        height: 120px;
    }
    .sidebar-blank-space{
        flex: 1;
    }
    .side-menu-item{
        border: none;
    }
    .side-menu-item:hover{
       cursor: pointer;
       background-color: $colorBackground;
    }
    .side-menu-item.active-menu{
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.1);
        .icon-menu{
            color: $colorOrange;
        }
    }
    .side-menu-item.active-menu:hover{
        cursor: default;
    }
}
.sidebar::-webkit-scrollbar-track{
    background-color: #fff;
}

.sidebar::-webkit-scrollbar{
    width: 8px;
    background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb{ 
    background-color: rgba(0, 0, 0, 0.1);
}

.sidebar.open{
    transform: translateX(0);
}

@media (max-width: 991.98px){
   
}
@media (min-width: 992px){
    .sidebar{
        transform: translateX(0);
    }
}