@import "../baseColor";

.container-aide {
    min-height: calc(100vh - 48px);

    // .aider-header {
    //     background-color: rgba($color: #000000, $alpha: 0.1);
    // }
.link-to-contact{
    color: blue;
}
.link-to-contact:hover{
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.9;
}
    .aide-menu {
        background-color: rgba(0, 0, 0, 0.1);
        height: calc(100vh - 110px);
        overflow-y: auto;
        .aide-menu-item{
            border-bottom: none;
        }
        .aide-menu-item:hover{
            cursor: pointer;
            background-color: $colorBackground;
        }

        .aide-menu-item.active-menu {
            font-weight: bold;
            background-color: rgba(0, 0, 0, 0.1);
        }

        .aide-menu-item.active-menu:hover {
            cursor: default;
        }

    }
    @media (max-width:"768.99px"){
        .aide-menu{
            height: auto;
        }
    }
}
