@import "./components/baseColor";

.main{
    background-color: $colorBackground;
    .main-container{
        padding-top: 48px;
        width:100% ;
    }
}


@media (min-width: 992px){
    .main{
        
        .main-container{
            padding-left:250px;
        }
    }
}
