@import "../baseColor";

.container-contact{
    min-height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    .row{
        flex: 1;
    }
    .contact-notice{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #warning-icon{
        color: $colorOrange;
    }
    .btn-send{
        background-color: $colorOrange;
        border: 2px solid $colorOrange;
    }
    .btn-send:hover{
        background-color: $colorWhite;
        color: $colorOrange;
        border: 2px solid $colorOrange;
    }
}