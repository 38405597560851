@import "../baseColor";

/////////////////////////////////////////////////////////////////////////
///////////////////////////// Home CSS /////////////////////////////////
///////////////////////////////////////////////////////////////////////
.container-home {
    min-height: calc(100vh - 48px);

    .home-nav {
        border-bottom: 2px solid $colorBackground;
        background-color: $colorWhite;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tab {
        background-color: $colorWhite;
        flex: 1;
        .active {
            background-color: $colorBlack;
            color: $colorWhite;
            font-weight: 500;
            border-bottom: 4px solid $colorBackground;
            .badge-number {
                background-color: $colorBackground;
                color: $colorBlack;
                font-weight: bold;
            }

            .icon-next-step {
                color: $colorOrange;
            }
        }

    }

    .tab:hover {
        background-color: rgba(0, 0, 0, 0.1);
        border-bottom: 2px solid $colorBackground;
        cursor: pointer;
    }

    .tab:hover .active {
        border-bottom: 2px solid $colorBackground;
    }

    .tab-row {
        .col-tab-fixed-height{
            background-color: rgba(0, 0, 0, 0.1);
            height: calc(100vh - 110px);
            box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
        }
        .empty-list-order {
            font-size: 1.4rem;
            font-weight: 300;
        }

        .no-selected-order{
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 115px);
            .message{
                font-size: 1.4rem;
                font-weight: 300;
                text-align: center;
            }
        }
    }


    .badge-number {
        background-color: $colorBlack;
        color: $colorWhite;
    }


    //////////////////////////////////////////////////////////////////////////////
    /////////////////////////// Nouvelles commandes CSS /////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    .order-item {
        display: flex;
        align-items: center;

        .order-info {
            flex: 1;

            .client-name {
                font-size: 0.7rem;
            }

        }

        .order-time {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $colorBlack;
            color: $colorOrange;
            border: 2px solid $colorBlack;

            .order-time-unit {
                font-size: 0.7rem;
                color: $colorWhite;
            }
        }

    }

    .order-item.active-item {
        background-color: $colorBlack;
        border: none;
        color: $colorWhite;
        .order-time{
            color: $colorWhite;
        }
    }

    .order-item.active-item:hover {
        cursor: default;
        background-color: $colorBlack;
    }

    .order-item:hover {
        background-color: $colorBackground;
        cursor: pointer;
    }

    //////////////////////////////////////////////////////////////////////////////
    ///////////////////// Details de nouvelles commandes CSS/////////////////////
    ////////////////////////////////////////////////////////////////////////////
    .order-details {
        height: calc(100vh - 114px);
        // border: 1px solid rgba(0, 0, 0, 0.1);
        // background-color: rgba(0, 0, 0, 0.1);
        // box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
        .order-details-client-info {
            display: flex;
            align-items: center;
            .details-name-number {
                flex: 1;
                .order-client-num {
                    font-size: 0.8rem;
                }
            }

            .order-date {
                font-size: 0.8rem;
            }
        }

        .foods-address-info {
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            .order-details-item {
                display: flex;
                font-size: 0.9rem;

                .order-details-item-quantity {
                    width: 60px;
                }

                .order-details-item-name {
                    flex: 1;
                }
            }

            .oder-details-total {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .oder-details-total-value {
                   font-weight: 300;
                   font-size: 1.2rem;
                }
            }
        }

        .btn-confirm {
            background-color: $colorGreen;
            border: 2px solid $colorGreen;
        }

        .btn-confirm:hover {
            background-color: $colorWhite;
            border: 2px solid $colorGreen;
            color: $colorGreen;
        }

        .btn-reject {
            background-color: $colorRed;
            border: 2px solid $colorRed;
        }

        .btn-reject:hover {
            background-color: $colorWhite;
            border: 2px solid $colorRed;
            color: $colorRed;
        }
    }
}

.riders-modal-header{
    background-color: rgba(0, 0, 0, 0.1);
    p{
        font-weight: 300;
    }
}
.riders-modal-body {
    .list-riders {
        box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.1);

        .empty-riders-city-list {
            font-size: 1.2rem;
            font-weight: 300;
        }

        .list-riders-city {
            display: flex;
            align-items: center;

            .div-text-info {
                flex: 1;

                p {
                    font-size: 0.7rem;
                }
            }
        }

        .list-riders-city.active-item {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .list-riders-city:hover {
            cursor: pointer;
            background-color: $colorBackground;
        }

        .list-riders-city.active-item:hover {
            background-color: rgba(0, 0, 0, 0.1);
            cursor: default;
        }
    }
}


@media (max-width: 767.98px) {
    .xxx {
        display: none;
    }
}

///////////////////////////////////////////////////////////////
/////// Commandes prise en charge pour livraison CSS /////////
/////////////////////////////////////////////////////////////

.deliver-order-item {
    display: flex;
    align-items: center;

    .deliver-order-item-info {
        flex: 1;

        .time-delivery {
            font-size: 0.7rem;
        }
    }
}

.deliver-order-item.active-item {
    background-color: $colorBlack;
    color: $colorWhite;
}

.deliver-order-item:hover {
    background-color: $colorBackground;
    cursor: pointer;
}

.deliver-order-item.active-item:hover {
    cursor: default;
    background-color: $colorBlack;
}

.order-rider-num {
    font-size: 0.8rem;
}

/////////////////////////////////////////////////////////////////////////
///////////// Commandes en cours de preparation ////////////////////////

.preparing-order-item {
    display: flex;
    align-items: center;

    .preparing-order-item-info {
        flex: 1;

        .time-delivery {
            font-size: 0.7rem;
        }
    }
}

.preparing-order-item.active-item {
    background-color: $colorBlack;
    color: $colorWhite;
}

.preparing-order-item:hover {
    background-color: $colorBackground;
    cursor: pointer;
}

.preparing-order-item.active-item:hover {
    cursor: default;
    background-color: $colorBlack;
}

#preparing-order-item-active {
    background-color: $colorBackground;
}

@media (max-width:"767.99px"){
    .col-details-new-order{
        display: none;
    }
}
@media (min-width:"768px"){
    .modal-details-new-order{
        display: none;
    }
}
