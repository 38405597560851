@import '../baseColor';

header{
    position: fixed;
    z-index: 100;
    height: 48px;
    width: calc(100% - 250px);
    display: flex;
    align-items: center;
    .blank-space{
        flex: 1;
    }
    .btn-navbar{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .btn-busy{
        border: 2px solid $colorOrange;
        color: $colorOrange;
        outline: none;
    }
    .btn-busy:hover{
        background-color: rgba(255, 153, 0, 0.788);
        border: solid 2px $colorOrange;
        color: $colorBlack;
    }
    .btn-deconnexion{
        background-color: $colorOrange;
        color: $colorBlack;
        border: solid 2px $colorOrange;
    }
    .btn-deconnexion:hover{
        background-color: rgba(255, 153, 0, 0.788);
        color: $colorBlack;
        border: solid 2px $colorOrange;
    }
    .no-internet-connexion{
        display: flex;
        align-items: center;
        .icon-wifi{
            font-size: 1.4rem;
        }
        p{
            font-size: 0.7rem;
        }
    }
    .status-text:hover{
        cursor: pointer;

    }
}

@media (min-width: 992px){
    header{
        left: 250px;
    }
    
}

@media (max-width:991.98px){
    header{
        left: 0px;
        width: 100%;
        // .btn-navbar{
        //     display: none;
        // }
    }
}

