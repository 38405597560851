@import "../baseColor";

.container-historique{
    min-height: calc(100vh - 48px);
    .header-list-historique{
        background-color: rgba(0, 0, 0,0.1);
    }
    .header-search{
        background-color: rgba(0, 0, 0,0.1);
        border: none;
        .search-input-container{
            display: flex;
            align-items: center;
            input{
                border-radius: 0px;
                border: none;
                
            }
            .date-filter-input{
                width: 150px;
                border: 3px solid $colorBackground;
            }
            .btn{
                border: none;
                border-radius: 0px;
            }

        }
    }
}
.order-item-historique{
    background-color: $colorWhite;
    .cmd-col-commande{
        display: flex;
        flex-direction: column;
        .cmd-id{
            font-size: 0.8rem;
        }
        .cmd-date{
            font-size: 0.6rem;
        }
    }

    .cmd-col-client{
        display: flex;
        flex-direction: column;
        .cmd-client-name{
            font-size: 0.8rem;
        }
        .cmd-client-phone{
            font-size: 0.6rem;
        }
    }

    .cmd-col-rider{
        display: flex;
        align-items: center;
        .cmd-col-rider-info{
            flex: 1;
            display: flex;
            flex-direction: column;
            .cmd-rider-name{
                font-size: 0.8rem;
            }
            .cmd-rider-phone{
                font-size: 0.6rem;
            }
        }
    }
    .details-order{
        box-shadow: 1px 2px 3px rgba(0, 0, 0,0.1);
        border-radius: 5px;
        .client-number{
            font-size: 0.8rem;
        }
        .order-details-item{
            display: flex;
            align-items: center;
            .order-details-item-name{
                flex: 1;
            }
        }
        .rider-phone{
            font-size: 0.8rem;
        }
        .order-details-total{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}
.order-item-historique.activeItem{
    background-color: rgba(0, 0, 0,0.1);
    .details-order{
        background-color: $colorWhite;
    }
    .cmd-row{
        
    }
}
.order-item-historique:hover{
    background-color: $colorBackground;
    cursor: pointer;
}
.order-item-historique.activeItem:hover{
    cursor: default;
    background-color: rgba(0, 0, 0,0.1);
}

.heading-his{
    font-size: 0.9rem;
}
.fixed-history-order-list{
    height: calc(100vh - 180px);
    background-color: rgba(0, 0, 0,0.1);
}